/** Статусы бронирования */
export const BookingStatuses = {
    Undefined: 'Undefined',                               // Неизвестный статус
    SelectPurchaseConditions: 'SelectPurchaseConditions', // Выбор способа покупки
    CheckPersonalData: 'CheckPersonalData',               // Проверка персональных данных
    Offer: 'Offer',                                       // Проверка договора оферты
    Payment: 'Payment',                                   // Требуется оплата
    Expired: 'Expired',                                   // Истекшее / Не завершено
    Wait: 'Wait',                                         // Требуется подтверждение менеджера
    Approved: 'Approved',                                 // Одобрено / Успешно забронировано
    Refusal: 'Refusal',                                   // Не одобрено / Результат не успешный
    Deal: 'Deal',                                         // Договор
    Cancelled: 'Cancelled',                               // Отменено
} as const;

/** Статусы бронирования UI*/
export const BookingUIStatuses = {
    Undefined: 'Undefined',                               // Неизвестный статус
    InProgress: 'InProgress',                             // В процессе
    Expired: 'Expired',                                   // Истекшее / Не завершено
    Wait: 'Wait',                                         // Требуется подтверждение менеджера
    Approved: 'Approved',                                 // Одобрено / Успешно забронировано
    Refusal: 'Refusal',                                   // Не одобрено / Результат не успешный
    Deal: 'Deal',                                         // Договор
    Cancelled: 'Cancelled',                               // Отменено
} as const;
